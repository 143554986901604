import axios from 'axios';
import FileSaver from 'file-saver';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { graduationListActions } from '../../actions';

function* getFacultyDepartments(actions) {
  try {
    const response = yield axios({
      url: '/results-mgt/graduation/departments',
      method: 'GET',
      params: actions.params,
    });
    yield put({
      type: graduationListActions.GET_FACULTY_DEPARTMENTS_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: graduationListActions.GET_FACULTY_DEPARTMENTS_ERROR,
      error: error.data,
    });
  }
}

function* getDepartmentProgrammes(actions) {
  try {
    const response = yield axios({
      url: `/results-mgt/reports/programmes`,
      method: 'GET',
      params: actions.params,
    });
    yield put({
      type: graduationListActions.GET_DEPARTMENT_PROGRAMMES_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: graduationListActions.GET_DEPARTMENT_PROGRAMMES_ERROR,
      error: error.data,
    });
  }
}

function* getAcademicUnitProgrammes(actions) {
  try {
    const response = yield axios({
      url: `/endpoint-required`,
      method: 'GET',
      params: actions.data,
    });
    yield put({
      type: graduationListActions.GET_ACADEMIC_UNIT_PROGRAMMES_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: graduationListActions.GET_ACADEMIC_UNIT_PROGRAMMES_ERROR,
      error: error.data,
    });
  }
}

function* getProvisionalList(actions) {
  try {
    const response = yield axios({
      url: `/results-mgt/graduation/draft-list`,
      method: 'GET',
      params: actions.params,
    });
    yield put({
      type: graduationListActions.GET_PROVISIONAL_LIST_SUCCESS,
      data: response.data,
      context: actions.params,
    });
  } catch (error) {
    yield put({
      type: graduationListActions.GET_PROVISIONAL_LIST_ERROR,
      error: error.data,
    });
  }
}

function* getFinalistsList(actions) {
  try {
    const { params } = actions;
    const response = yield axios({
      url: `/results-mgt/graduation/finalists`,
      method: 'GET',
      params,
    });
    yield put({
      type: graduationListActions.GET_FINALISTS_LIST_SUCCESS,
      data: response.data,
      context: params,
    });
  } catch (error) {
    yield put({
      type: graduationListActions.GET_FINALISTS_LIST_ERROR,
      error: error.data,
    });
  }
}

function* getPhDFinalistsList(actions) {
  try {
    const { params } = actions;
    const response = yield axios({
      url: `/results-mgt/graduation/phd-student-list`,
      method: 'GET',
      params,
    });
    yield put({
      type: graduationListActions.GET_PHD_FINALISTS_LIST_SUCCESS,
      data: response.result,
      context: params,
    });
  } catch (error) {
    yield put({
      type: graduationListActions.GET_PHD_FINALISTS_LIST_ERROR,
      error: error.data,
    });
  }
}

function* getResultList(actions) {
  try {
    const { params } = actions;
    const response = yield axios({
      url: `/results-mgt/graduation/result-list`,
      method: 'POST',
      data: params,
    });
    yield put({
      type: graduationListActions.GET_RESULT_LIST_SUCCESS,
      data: response.data,
      context: params,
    });
  } catch (error) {
    yield put({
      type: graduationListActions.GET_RESULT_LIST_ERROR,
      error: error.data,
    });
  }
}

function* getGraduationList(actions) {
  try {
    const response = yield axios({
      url: `/results-mgt/graduation/final-list`,
      method: 'GET',
      params: actions.params,
    });
    yield put({
      type: graduationListActions.GET_GRADUATION_LIST_SUCCESS,
      data: response.data,
      context: actions.params,
    });
  } catch (error) {
    yield put({
      type: graduationListActions.GET_GRADUATION_LIST_ERROR,
      error: error.data,
    });
  }
}

function* getGraduatedStudents(actions) {
  const { params } = actions;
  try {
    const response = yield axios({
      url: `/results-mgt/graduation/graduated-students`,
      method: 'GET',
      params,
    });
    yield put({
      type: graduationListActions.GET_GRADUATED_STUDENTS_SUCCESS,
      data: response.data,
      context: params,
    });
  } catch (error) {
    yield put({
      type: graduationListActions.GET_GRADUATED_STUDENTS_ERROR,
      error: error.data,
    });
  }
}

function* generateProvisionalList(actions) {
  try {
    const response = yield axios({
      url: `/results-mgt/graduation/provisional-graduation-list`,
      method: 'POST',
      data: actions.data,
      params: actions.params,
      timeout: 1200000,
    });
    const options = {
      ...actions.params,
      academic_year: actions.params.graduation_academic_year_id,
      entry_academic_year: actions.params.academic_year,
    };
    delete options.graduation_academic_year_id;
    yield put({
      type: graduationListActions.GENERATE_PROVISIONAL_LIST_SUCCESS,
      data: response.data,
      context: actions.params,
    });
    yield put({
      type: graduationListActions.GET_PROVISIONAL_LIST_REQUEST,
      params: options,
    });
    yield put({ type: 'SHOW_MODAL', payload: false });
  } catch (error) {
    yield put({
      type: graduationListActions.GENERATE_PROVISIONAL_LIST_ERROR,
      error: error.data,
    });
  }
}

function* updateGraduationYear(actions) {
  try {
    const response = yield axios({
      url: `/results-mgt/graduation/update-provisional-list`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: graduationListActions.UPDATE_STUDENT_GRADUATION_YEAR_SUCCESS,
      data: response.data,
    });
    yield put({
      type: graduationListActions.GET_PROVISIONAL_LIST_REQUEST,
      params: actions.contextData,
    });
    yield put({ type: 'SHOW_MODAL', payload: false });
  } catch (error) {
    yield put({
      type: graduationListActions.UPDATE_STUDENT_GRADUATION_YEAR_ERROR,
      error: error.data,
    });
  }
}

function* addToProvisionalList(actions) {
  try {
    const response = yield axios({
      url: `/results-mgt/graduation/administrative-provisional-graduation-list`,
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: graduationListActions.ADD_TO_PROVISIONAL_LIST_SUCCESS,
      data: response.data,
    });
    yield put({ type: 'SHOW_MODAL', payload: false });
  } catch (error) {
    yield put({
      type: graduationListActions.ADD_TO_PROVISIONAL_LIST_ERROR,
      error: error.data,
    });
  }
}

function* addPhdToProvisionalList(actions) {
  try {
    const response = yield axios({
      url: `/results-mgt/graduation/push-phd-to-provisional`,
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: graduationListActions.ADD_PHD_TO_PROVISIONAL_LIST_SUCCESS,
      data: response.data,
    });
    yield put({ type: 'SHOW_MODAL', payload: false });
    yield put({
      type: graduationListActions.GET_PHD_FINALISTS_LIST_REQUEST,
      params: actions.context,
    });
  } catch (error) {
    yield put({
      type: graduationListActions.ADD_PHD_TO_PROVISIONAL_LIST_ERROR,
      error: error.data,
    });
  }
}

function* removeFromProvisionalList(actions) {
  try {
    const { params, data } = actions;

    const response = yield axios({
      url: `/results-mgt/graduation/remove-from-provisional-list`,
      method: 'DELETE',
      data,
    });
    yield put({
      type: graduationListActions.REMOVE_FROM_PROVISIONAL_LIST_SUCCESS,
      data: response.data,
    });
    yield put({
      type: graduationListActions.GET_PROVISIONAL_LIST_REQUEST,
      params,
    });
    yield put({
      type: graduationListActions.SET_SHOW_CONFIRM_MODAL,
      payload: false,
    });
    yield put({
      type: graduationListActions.SET_SELECTED_STUDENT_LIST,
      payload: [],
    });
  } catch (error) {
    yield put({
      type: graduationListActions.REMOVE_FROM_PROVISIONAL_LIST_ERROR,
      error: error.data,
    });
  }
}

function* removeFromFinalList(actions) {
  try {
    const { params, data } = actions;

    const response = yield axios({
      url: `/results-mgt/graduation/remove-from-final-list`,
      method: 'DELETE',
      data,
    });
    yield put({
      type: graduationListActions.REMOVE_FROM_FINAL_LIST_SUCCESS,
      data: response.data,
    });
    yield put({
      type: graduationListActions.GET_GRADUATION_LIST_REQUEST,
      params,
    });
    yield put({
      type: graduationListActions.SET_SHOW_CONFIRM_MODAL,
      payload: false,
    });
    yield put({
      type: graduationListActions.SET_SELECTED_STUDENT_LIST,
      payload: [],
    });
  } catch (error) {
    yield put({
      type: graduationListActions.REMOVE_FROM_FINAL_LIST_ERROR,
      error: error.data,
    });
  }
}

function* downloadDraftResults(actions) {
  try {
    yield axios({
      url: '/results-mgt/graduation/download-draft-list',
      method: 'POST',
      params: actions.params,
      responseType: 'blob',
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${process.env.REACT_APP_INSTITUTION_NAME}-DRAFT-GRADUATION-LIST.xlsx`
      );
    });
    yield put({
      type: graduationListActions.DOWNLOAD_DRAFT_RESULTS_SUCCESS,
      data: { server: { message: 'File downloaded!', status: true } },
    });
  } catch (error) {
    yield put({
      type: graduationListActions.DOWNLOAD_DRAFT_RESULTS_ERROR,
      error: error.data,
    });
  }
}

function* downloadGraduationList(actions) {
  try {
    yield axios({
      url: '/results-mgt/graduation/download-final-list',
      method: 'POST',
      params: actions.params,
      responseType: 'blob',
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${process.env.REACT_APP_INSTITUTION_NAME}-FINAL-GRADUATION-LIST.xlsx`
      );
    });
    yield put({
      type: graduationListActions.DOWNLOAD_FINAL_GRAD_LIST_SUCCESS,
      data: { server: { message: 'File downloaded!', status: true } },
    });
  } catch (error) {
    yield put({
      type: graduationListActions.DOWNLOAD_FINAL_GRAD_LIST_ERROR,
      error: error.data,
    });
  }
}

function* searchFinalist(actions) {
  try {
    const response = yield axios({
      url: `/results-mgt/graduation/single-student`,
      method: 'GET',
      params: actions.params,
    });
    yield put({
      type: graduationListActions.SEARCH_FINALIST_SUCCESS,
      data: { data: response.data, student: actions.params.student },
    });
  } catch (error) {
    yield put({
      type: graduationListActions.SEARCH_FINALIST_ERROR,
      error: error.data,
    });
  }
}

function* addToGradList(actions) {
  try {
    const response = yield axios({
      url: `/results-mgt/graduation/push-to-graduation-list`,
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: graduationListActions.ADD_TO_GRAD_LIST_SUCCESS,
      data: response.data,
    });
    yield put({
      type: graduationListActions.SET_SHOW_CONFIRM_MODAL,
      payload: false,
    });
    yield put({
      type: graduationListActions.SET_SELECTED_STUDENT_LIST,
      payload: [],
    });
    yield put({
      type: graduationListActions.GET_PROVISIONAL_LIST_REQUEST,
      params: actions.params,
    });
  } catch (error) {
    yield put({
      type: graduationListActions.ADD_TO_GRAD_LIST_ERROR,
      error: error.data,
    });
  }
}

function* addCategoryToProvisionalList(actions) {
  try {
    const { data, params } = actions;

    const response = yield axios({
      url: `/results-mgt/graduation/push-to-provisional`,
      method: 'POST',
      data,
    });
    yield put({
      type: graduationListActions.ADD_CATEGORY_TO_PROVISIONAL_LIST_SUCCESS,
      data: response.data,
    });
    yield put({
      type: graduationListActions.SET_SHOW_CONFIRM_MODAL,
      payload: false,
    });
    yield put({
      type: graduationListActions.SET_SELECTED_STUDENT_LIST,
      payload: [],
    });
    yield put({
      type: graduationListActions.GET_PROVISIONAL_LIST_REQUEST,
      params,
    });
    yield put({
      type: graduationListActions.GET_FINALISTS_LIST_REQUEST,
      params,
    });
  } catch (error) {
    yield put({
      type: graduationListActions.ADD_CATEGORY_TO_PROVISIONAL_ERROR,
      error: error.data,
    });
  }
}

function* graduateStudents(actions) {
  try {
    const { data, context } = actions;
    const response = yield axios({
      url: `/results-mgt/graduation/graduate-students`,
      method: 'POST',
      data,
      timeout: 300000,
    });
    yield put({
      type: graduationListActions.GRADUATE_STUDENTS_SUCCESS,
      data: response.data,
    });
    yield put({
      type: graduationListActions.GET_GRADUATION_LIST_REQUEST,
      params: context,
    });
    yield put({
      type: graduationListActions.SHOW_PRINT_MODAL,
      e: false,
    });
  } catch (error) {
    yield put({
      type: graduationListActions.GRADUATE_STUDENTS_ERROR,
      error: error.data,
    });
  }
}

function* billGraduationListStudents(actions) {
  try {
    const { data, category, context } = actions;

    const response = yield axios({
      url: `/results-mgt/graduation/bill-students`,
      method: 'POST',
      data,
    });
    yield put({
      type: graduationListActions.BILL_GRAD_LIST_STUDENTS_SUCCESS,
      data: response.data,
    });
    if (category === 'provisional') {
      yield put({
        type: graduationListActions.GET_PROVISIONAL_LIST_REQUEST,
        params: actions.context,
      });
    } else {
      yield put({
        type: graduationListActions.GET_GRADUATION_LIST_REQUEST,
        params: context,
      });
    }
  } catch (error) {
    yield put({
      type: graduationListActions.BILL_GRAD_LIST_STUDENTS_ERROR,
      error: error.data,
    });
  }
}

function* watchGetFacultyDepartments() {
  yield takeLatest(
    graduationListActions.GET_FACULTY_DEPARTMENTS_REQUEST,
    getFacultyDepartments
  );
}

function* watchGetDepartmentProgrammes() {
  yield takeLatest(
    graduationListActions.GET_DEPARTMENT_PROGRAMMES_REQUEST,
    getDepartmentProgrammes
  );
}

function* watchGetAcademicUnitProgrammes() {
  yield takeLatest(
    graduationListActions.GET_ACADEMIC_UNIT_PROGRAMMES_REQUEST,
    getAcademicUnitProgrammes
  );
}

function* watchGetProvisionalList() {
  yield takeLatest(
    graduationListActions.GET_PROVISIONAL_LIST_REQUEST,
    getProvisionalList
  );
}

function* watchGetFinalistsList() {
  yield takeLatest(
    graduationListActions.GET_FINALISTS_LIST_REQUEST,
    getFinalistsList
  );
}

function* watchGetPhDFinalistsList() {
  yield takeLatest(
    graduationListActions.GET_PHD_FINALISTS_LIST_REQUEST,
    getPhDFinalistsList
  );
}

function* watchGetResultList() {
  yield takeLatest(
    graduationListActions.GET_RESULT_LIST_REQUEST,
    getResultList
  );
}

function* watchGetGraduationList() {
  yield takeLatest(
    graduationListActions.GET_GRADUATION_LIST_REQUEST,
    getGraduationList
  );
}

function* watchDownloadGraduationList() {
  yield takeLatest(
    graduationListActions.DOWNLOAD_FINAL_GRAD_LIST_REQUEST,
    downloadGraduationList
  );
}

function* watchGetGraduatedStudents() {
  yield takeLatest(
    graduationListActions.GET_GRADUATED_STUDENTS_REQUEST,
    getGraduatedStudents
  );
}

function* watchGenerateProvisionalList() {
  yield takeLatest(
    graduationListActions.GENERATE_PROVISIONAL_LIST_REQUEST,
    generateProvisionalList
  );
}

function* watchUpdateGraduationYear() {
  yield takeLatest(
    graduationListActions.UPDATE_STUDENT_GRADUATION_YEAR_REQUEST,
    updateGraduationYear
  );
}

function* watchAddToProvisionalList() {
  yield takeLatest(
    graduationListActions.ADD_TO_PROVISIONAL_LIST_REQUEST,
    addToProvisionalList
  );
}

function* watchAddPhdToProvisionalList() {
  yield takeLatest(
    graduationListActions.ADD_PHD_TO_PROVISIONAL_LIST_REQUEST,
    addPhdToProvisionalList
  );
}

function* watchRemoveFromProvisionalList() {
  yield takeLatest(
    graduationListActions.REMOVE_FROM_PROVISIONAL_LIST_REQUEST,
    removeFromProvisionalList
  );
}

function* watchRemoveFromFinalList() {
  yield takeLatest(
    graduationListActions.REMOVE_FROM_FINAL_LIST_REQUEST,
    removeFromFinalList
  );
}

function* watchDownloadDraftResults() {
  yield takeLatest(
    graduationListActions.DOWNLOAD_DRAFT_RESULTS_REQUEST,
    downloadDraftResults
  );
}

function* watchSearchFinalist() {
  yield takeLatest(
    graduationListActions.SEARCH_FINALIST_REQUEST,
    searchFinalist
  );
}

function* watchAddToGradList() {
  yield takeLatest(
    graduationListActions.ADD_TO_GRAD_LIST_REQUEST,
    addToGradList
  );
}

function* watchAddCategoryToProvisionalList() {
  yield takeLatest(
    graduationListActions.ADD_CATEGORY_TO_PROVISIONAL_LIST_REQUEST,
    addCategoryToProvisionalList
  );
}

function* watchGraduateStudents() {
  yield takeLatest(
    graduationListActions.GRADUATE_STUDENTS_REQUEST,
    graduateStudents
  );
}

function* watchBillGraduationListStudents() {
  yield takeLatest(
    graduationListActions.BILL_GRAD_LIST_STUDENTS_REQUEST,
    billGraduationListStudents
  );
}

export default [
  fork(watchGetFacultyDepartments),
  fork(watchGetDepartmentProgrammes),
  fork(watchGetAcademicUnitProgrammes),
  fork(watchGetProvisionalList),
  fork(watchGetFinalistsList),
  fork(watchGetPhDFinalistsList),
  fork(watchGetResultList),
  fork(watchGetGraduationList),
  fork(watchDownloadGraduationList),
  fork(watchGetGraduatedStudents),
  fork(watchDownloadDraftResults),
  fork(watchAddToProvisionalList),
  fork(watchRemoveFromProvisionalList),
  fork(watchRemoveFromFinalList),
  fork(watchGenerateProvisionalList),
  fork(watchUpdateGraduationYear),
  fork(watchSearchFinalist),
  fork(watchAddToGradList),
  fork(watchAddPhdToProvisionalList),
  fork(watchAddCategoryToProvisionalList),
  fork(watchGraduateStudents),
  fork(watchBillGraduationListStudents),
];
