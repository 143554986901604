import RoutePaths from '../routes/routePath';

const setting = {
  setting: {
    selectedMenu: { ...RoutePaths.dashboard },
    currentUnebCenterTab: 'manage',
    parentTab: 'default',
    appNavigation: {},
    // Modals
    showVerifyDocsModal: false,
    showDeleteModal: false,
    showEditUserModal: false,
    showThemeModal: false,
    showModal: false,
    showPRNModal: false,
    showSponsorReceiptModal: false,
    openModal: false,
    showUploadModal: false,
    showEditAccessDomainModal: false,
    showAccessDomainModal: false,
    showAllocateTransactionModal: false,
    showCreateMetadataModal: false,
    showEditMetadataValueModal: false,
    showCollegeModal: false,
    showFacultyModal: false,
    showCreateDepartmentModal: false,
    showDepartmentModal: false,
    showEditSubjectsModal: false,
    showEditSpecializationModal: false,
    showEditUnebSubjectModal: false,
    showCreateSubjectCombinationModal: false,
    showEditSemesterLoadModal: false,
    showEditProgrammeAliasModal: false,
    showEditTuition: false,
    showEditFeesDiscountModal: false,
    showOffsetInvoiceModal: { show: false, invoiceData: {} },
    showInvoiceActionsModal: { show: false, invoices: [], url: null },
    showPrintStudentLedgerModal: false,
    showFacultyDownloadModal: false,
    showGeneratePRNModal: false,
    showAdministrativeAdmissionModal: false,
    showResultsApprovalModal: false,
    showUniversalViewReceiptModal: false,

    currentPujabTab: 'all-admissions',

    selectedProgramme: {},
    selectedProgrammeVersion: {},
    showAddRoleApplicationModal: false,
    selectedUserProfile: {},
    showCreateRoleGroupModal: false,
    showEditRoleGroupModal: false,
    showCreateSecurityProfileModal: false,
    selectedSecurityProfileGroup: {},
    showCreateUserRoleModal: false,
    showEditUserRoleModal: false,
    showAssignUserRoleModal: false,
    showAddRoleGroupAdminModal: false,
    showAssignRoleToUserModal: false,
    showOpenAdmissionsModal: false,
    isEditingAdmission: false,
    admissionEditAction: null,
    admissionToEdit: null,
    showAdvertisingProgramModal: false,
    showEditEventModal: false,
    showDeleteEventModal: false,
    showSingleApplicationFormModal: false,
    showFormEditModal: false,
    showBuildingModal: 'viewBuilding',
    showRoomsModal: 'viewRooms',
    showDeleteRoom: false,
    showEditRoom: false,
    showEditModal: false,
    showDetailsModal: false,
    showDeleteVersionCUModal: false,
    showResetPasswordModal: false,
    showAdmitStudentModal: false,
    showCreateStudentModal: false,
    showImportApplicantModal: false,
    programmesToAdvertise: [],
    showEditAdmittedStudentModal: false,
    showChangeOfProgrammeModal: false,
    showPrintAdmissionModal: false,
    showUploadCourseWorkModal: false,
    showUploadFinalWorkModal: false,
    showRegistrationModal: { show: false, usage: 'fullRegistration' },
    showDeRegisterModal: false,
    showRegisterFullyModal: false,
    showLateEnrollmentModal: false,
    showRetakeEnrollmentModal: false,
    showEnrollmentModal: false,
    showDeEnrollModal: false,
    showTransferFundsModal: false,
    showPujabProgrammesModal: false,
    showActionAuthenticationModal: { show: false, usage: 'uploadResults' },
    eventContextData: {},
    selectedUserRole: {},
    selectedSystemApp: {},
    selectedSystemUser: {},
    selectedAcademicYear: {},
    selectedAdmissionScheme: {},

    resultProgrammes: [],
    selectedResultProgramme: {},
    selectedResultContext: {},
  },
};

export default setting;
