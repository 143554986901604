import axios from 'axios';
import FileSaver from 'file-saver';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { studentsActions } from '../../actions';
import { getAccessToken } from '../../services/storageService';

function* getStudents(actions) {
  try {
    const response = yield axios({
      url: '/students-mgt/students/students-data',
      method: 'GET',
      params: actions.context,
    });
    yield put({
      type: studentsActions.GET_STUDENTS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: studentsActions.GET_STUDENTS_ERROR,
      error: error.data,
    });
  }
}

function* getContextStudents(actions) {
  try {
    const response = yield axios({
      url: '/students-mgt/students/students-data',
      method: 'POST',
      data: actions.context,
    });
    yield put({
      type: studentsActions.GET_CONTEXT_STUDENTS_SUCCESS,
      data: response,
      context: actions.context,
    });
  } catch (error) {
    yield put({
      type: studentsActions.GET_CONTEXT_STUDENTS_ERROR,
      error: error.data,
    });
  }
}

function* fetchSingleStudent(actions) {
  try {
    const response = yield axios({
      url: `/registration/student-records/fetch-student?student=${actions.query}`,
      method: 'GET',
    });
    yield put({
      type: studentsActions.GET_SINGLE_STUDENT_SUCCESS,
      data: response.data,
      studentIdentifier: actions.query,
    });
    if (actions.purpose === 'upload') {
      yield put({
        type: studentsActions.SET_UPLOAD_STUDENT,
        data: response.data,
      });
    } else if (actions.purpose === 'identification') {
      yield put({
        type: studentsActions.SET_IDENTIFYING_STUDENT,
        data: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: studentsActions.GET_SINGLE_STUDENT_ERROR,
      error: error.data,
    });
    if (actions.purpose === 'identification') {
      yield put({
        type: studentsActions.SET_IDENTIFYING_STUDENT,
        data: {},
      });
    } else if (actions.purpose === 'upload') {
      yield put({
        type: studentsActions.SET_UPLOAD_STUDENT,
        data: {},
      });
    }
  }
}

function* getStudentByRegNo(actions) {
  try {
    const response = yield axios({
      url: `/registration/student-records/${actions.regNo}`,
      method: 'GET',
    });
    yield put({
      type: studentsActions.GET_STUDENT_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: studentsActions.GET_STUDENT_ERROR,
      error: error.data,
    });
  }
}

function* getRegistrationStatus(actions) {
  try {
    const response = yield axios({
      url: `/registration/enrollments/current-semester-events/${actions.studentId}`,
      method: 'GET',
    });
    yield put({
      type: studentsActions.GET_REGISTRATION_STATUS_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: studentsActions.GET_REGISTRATION_STATUS_ERROR,
      error: error.data,
    });
  }
}

function* getAllStudents() {
  try {
    const response = yield axios({
      url: '/students-mgt/students',
      method: 'GET',
    });
    yield put({
      type: studentsActions.GET_ALL_STUDENTS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: studentsActions.GET_ALL_STUDENTS_ERROR,
      error: error.data,
    });
  }
}

function* getFeesWaiverStudents(actions) {
  try {
    const response = yield axios({
      url: '/students-mgt/students-records/student-waiver-list',
      method: 'GET',
      params: actions.context,
    });
    yield put({
      type: studentsActions.GET_FEES_WAIVER_STUDENTS_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: studentsActions.GET_FEES_WAIVER_STUDENTS_ERROR,
      error: error.data,
    });
  }
}

function* updateStudentWaiverLimit(actions) {
  try {
    const response = yield axios({
      url: '/students-mgt/students-records/update-waiver-limits',
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: studentsActions.UPDATE_STUDENT_FEES_WAIVER_LIMIT_SUCCESS,
      data: response,
    });
    yield put({
      type: 'SHOW_EDIT_MODAL',
      payload: false,
    });
    yield put({
      type: studentsActions.GET_FEES_WAIVER_STUDENTS_REQUEST,
      context: actions.context,
    });
  } catch (error) {
    yield put({
      type: studentsActions.UPDATE_STUDENT_FEES_WAIVER_LIMIT_ERROR,
      error: error.data,
    });
  }
}

function* getStudentSDashboard() {
  try {
    const response = yield axios({
      url: '/students-mgt/students-reports',
      method: 'GET',
    });
    yield put({
      type: studentsActions.GET_DASHBOARD_STUDENTS_SUCCESS,
      data: response.studentsReport,
    });
  } catch (error) {
    yield put({
      type: studentsActions.GET_DASHBOARD_STUDENTS_ERROR,
      error: error.data,
    });
  }
}

function* createStudent(actions) {
  try {
    const response = yield axios({
      url: '/students-mgt/students',
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: studentsActions.CREATE_STUDENTS_SUCCESS,
      data: response,
    });
    yield put({
      type: 'SHOW_CREATE_STUDENT_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: studentsActions.CREATE_STUDENTS_ERROR,
      error: error.data,
    });
  }
}

function* fetchProgrammeStudents(actions) {
  try {
    const response = yield axios({
      url: '/students-mgt/students/prog-students',
      method: 'POST',
      data: actions.context,
    });
    yield put({
      type: studentsActions.GET_PROGRAMME_STUDENTS_SUCCESS,
      data: response.students,
      context: actions.context,
    });
  } catch (error) {
    yield put({
      type: studentsActions.GET_PROGRAMME_STUDENTS_ERROR,
      error: error.data,
    });
  }
}

function* editStudentStatus(actions) {
  try {
    const { students, context } = actions;
    const response = yield axios({
      url: `/students-mgt/students/account-status`,
      method: 'PUT',
      data: { students },
    });
    yield put({
      type: studentsActions.EDIT_STUDENTS_STATUS_SUCCESS,
      data: response.data,
    });
    yield put({
      type: studentsActions.GET_CONTEXT_STUDENTS_REQUEST,
      context,
    });
    yield put({ type: 'SHOW_EDIT_ACCOUNT_MODAL', payload: false });
    yield put({ type: 'SHOW_SEARCH_ACCOUNT_MODAL', payload: false });
  } catch (error) {
    yield put({
      type: studentsActions.EDIT_STUDENTS_STATUS_ERROR,
      error: error.data,
    });
  }
}

function* editSingleStudentStatus(actions) {
  try {
    const { students, context } = actions;
    const response = yield axios({
      url: `/students-mgt/students/account-status`,
      method: 'PUT',
      data: { students },
    });
    yield put({
      type: studentsActions.EDIT_SINGLE_STUDENT_STATUS_SUCCESS,
      data: response.data,
    });
    if (Object.keys(context).length === 4) {
      yield put({
        type: studentsActions.GET_CONTEXT_STUDENTS_REQUEST,
        context,
      });
    }
    yield put({ type: 'SHOW_EDIT_ACCOUNT_MODAL', payload: false });
    yield put({ type: 'SHOW_SEARCH_ACCOUNT_MODAL', payload: false });
  } catch (error) {
    yield put({
      type: studentsActions.EDIT_SINGLE_STUDENT_STATUS_ERROR,
      error: error.data,
    });
  }
}

function* uploadStudentIdCardImage(actions) {
  try {
    const { cardSide, studentNumber } = actions;
    const response = yield axios({
      url: `/id-tracker/printed/upload-id/${cardSide}/${studentNumber}`,
      method: 'POST',
      data: actions.data,
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    yield put({
      type: studentsActions.UPLOAD_STUDENT_ID_CARD_IMAGE_SUCCESS,
      data: response.data,
    });

    // yield put({
    //   type: studentActions.GET_PRINTED_STUDENT_ID_CARDS_REQUEST,
    // });
    yield put({
      type: studentsActions.SET_UPLOAD_ID_IMAGE_CONTEX,
      context: { cardSide, studentNumber },
    });
  } catch (error) {
    yield put({
      type: studentsActions.UPLOAD_STUDENT_ID_CARD_IMAGE_ERROR,
      error: error.data,
    });
  }
}

function* bulkEditProgrammeVersion(actions) {
  try {
    const response = yield axios({
      url: '/students-mgt/students/prog-version',
      method: 'PUT',
      data: actions.context,
    });
    yield put({
      type: studentsActions.BULK_EDIT_PROGRAMME_VERSION_SUCCESS,
      data: response.students,
    });
    yield put({
      type: studentsActions.GET_PROGRAMME_STUDENTS_REQUEST,
      context: {
        programme_id: actions.context.programme_id,
        academic_year_id: actions.context.academic_year_id,
      },
    });
    yield put({
      type: studentsActions.GET_CONTEXT_STUDENTS_REQUEST,
      context: {
        programme_id: actions.context.programme_id,
        academic_year_id: actions.context.academic_year_id,
        campus_id: actions.studentContext.campus_id,
        intake_id: actions.studentContext.intake_id,
      },
    });
    yield put({ type: 'SHOW_EDIT_VERSION_MODAL', payload: false });
  } catch (error) {
    yield put({
      type: studentsActions.BULK_EDIT_PROGRAMME_VERSION_ERROR,
      error: error.data,
    });
  }
}

function* uploadFile(actions) {
  try {
    const token = getAccessToken();
    const { category, studentId, formData: data } = actions;
    const response = yield axios({
      url: `/students-mgt/students/upload-${category}/${studentId}`,
      method: 'POST',
      data,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
      },
      timeout: 300000,
    });
    yield put({
      type: studentsActions.UPLOAD_STUDENT_PHOTO_SUCCESS,
      data: response,
    });
    yield put({
      type: 'SHOW_MODAL',
      payload: false,
    });
    yield put({
      type: studentsActions.GET_SINGLE_STUDENT_REQUEST,
      query: studentId,
      purpose: 'upload',
    });
  } catch (error) {
    yield put({
      type: studentsActions.UPLOAD_STUDENT_PHOTO_ERROR,
      error: error.data,
    });
  }
}

function* deleteStudents(actions) {
  try {
    const response = yield axios({
      url: `/students-mgt/students/${actions.id}`,
      method: 'DELETE',
    });
    yield put({
      type: studentsActions.DELETE_STUDENTS_SUCCESS,
      data: response.data,
    });
    yield put({
      type: studentsActions.GET_STUDENTS_REQUEST,
      context: actions.currentContext,
    });
    yield put({
      type: 'SHOW_DELETE_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: studentsActions.DELETE_STUDENTS_ERROR,
      error: error.data,
    });
  }
}

function* updateStudent(actions) {
  try {
    const response = yield axios({
      url: `/students-mgt/students/${actions.id}`,
      method: 'PUT',
      data: actions.data,
    });
    yield put({
      type: studentsActions.UPDATE_STUDENTS_SUCCESS,
      data: response,
    });
    yield put({
      type: studentsActions.GET_STUDENTS_REQUEST,
      context: actions.currentContext,
    });
  } catch (error) {
    yield put({
      type: studentsActions.UPDATE_STUDENTS_ERROR,
      error,
    });
  }
}

function* updateStudentInfo(actions) {
  const { data, url } = actions;

  try {
    const response = yield axios({
      url: `/students-mgt/students/${url}`,
      method: 'PUT',
      data,
    });
    yield put({
      type: studentsActions.UPDATE_STUDENT_SUCCESS,
      data: response,
    });
    yield put({
      type: studentsActions.SHOW_STUDENT_PROFILE_MODAL,
      payload: false,
    });
  } catch (error) {
    yield put({
      type: studentsActions.UPDATE_STUDENT_ERROR,
      error: error.data,
    });
  }
}

function* getStudentProgrammeCourses(actions) {
  try {
    const response = yield axios({
      url: `/registration/student-registration/course-units/${actions.studentId}`,
      method: 'GET',
    });
    yield put({
      type: studentsActions.GET_STUDENT_PROGRAMME_COURSE_UNITS_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: studentsActions.GET_STUDENT_PROGRAMME_COURSE_UNITS_ERROR,
      error: error.data,
    });
  }
}

function* getStudentCoursesToEdit(actions) {
  try {
    const response = yield axios({
      url: `/registration/student-registration/student-courses/${actions.studentId}`,
      method: 'GET',
    });
    yield put({
      type: studentsActions.GET_STUDENT_COURSE_UNITS_TO_EDIT_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: studentsActions.GET_STUDENT_COURSE_UNITS_TO_EDIT_ERROR,
      error: error.data,
    });
  }
}

function* printPermit(actions) {
  try {
    const response = yield axios({
      url: `registration/student-registration/exam-card-constraints/${actions.registrationId}`,
      method: 'GET',
    });
    yield put({
      type: studentsActions.CHECK_EXAMINATION_PERMIT_SUCCESS,
      data: response.server,
      registrationId: actions.registrationId,
    });
  } catch (error) {
    yield put({
      type: studentsActions.CHECK_EXAMINATION_PERMIT_ERROR,
      error: error.data,
    });
  }
}

function* getLateCourseUnits(actions) {
  try {
    const response = yield axios({
      url: `/registration/student-registration/late-registration/course-units/${actions.studentProgId}/${actions.semesterId}`,
      method: 'GET',
    });
    yield put({
      type: studentsActions.GET_LATE_COURSES_TO_REGISTER_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: studentsActions.GET_LATE_COURSES_TO_REGISTER_ERROR,
      error: error.data,
    });
  }
}

function* getMultipleRegistrations(actions) {
  try {
    const response = yield axios({
      url: `/registration/enrollment-registration/exam-card-course`,
      method: 'POST',
      data: { registration: actions.data },
    });
    yield put({
      type: studentsActions.GET_CURRENT_REGISTRATIONS_SUCCESS,
      data: response.data,
      registrationIds: actions.data,
    });
  } catch (error) {
    yield put({
      type: studentsActions.GET_CURRENT_REGISTRATIONS_ERROR,
      error: error.data,
    });
  }
}

function* downloadProgrammeStudents(actions) {
  const { ...newParams } = actions.data;
  try {
    yield axios({
      url: `/registration/enrollment-registration/download-report`,
      method: 'POST',
      data: newParams,
      responseType: 'blob',
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${process.env.REACT_APP_INSTITUTION_NAME}-STUDENTS-DATA-TEMPLATE.xlsx`
      );
    });
    yield put({
      type: studentsActions.DOWNLOAD_PROGRAMME_STUDENTS_SUCCESS,
      data: { server: { message: 'Template downloaded!', status: true } },
    });
  } catch (error) {
    yield put({
      type: studentsActions.DOWNLOAD_PROGRAMME_STUDENTS_ERROR,
      error: error.data ? error.data : error,
    });
  }
}

function* downloadSRMProgrammeStudentsByContext(actions) {
  try {
    yield axios({
      url: `students-mgt/students/download-students-by-context`,
      method: 'POST',
      params: actions.context,
      responseType: 'blob',
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${process.env.REACT_APP_INSTITUTION_NAME}-STUDENT-RECORDS-TEMPLATE.xlsx`
      );
    });
    yield put({
      type: studentsActions.DOWNLOAD_SRM_STUDENTS_BY_CONTEXT_SUCCESS,
      data: { server: { message: 'Template downloaded!', status: true } },
    });
  } catch (error) {
    yield put({
      type: studentsActions.DOWNLOAD_SRM_STUDENTS_BY_CONTEXT_ERROR,
      error: error.data ? error.data : error,
    });
  }
}

function* watchGetStudents() {
  yield takeLatest(studentsActions.GET_STUDENTS_REQUEST, getStudents);
}

function* watchGetStudentSDashboard() {
  yield takeLatest(
    studentsActions.GET_DASHBOARD_STUDENTS_REQUEST,
    getStudentSDashboard
  );
}

function* watchGetContextStudents() {
  yield takeLatest(
    studentsActions.GET_CONTEXT_STUDENTS_REQUEST,
    getContextStudents
  );
}

function* watchGetSingleStudent() {
  yield takeLatest(
    studentsActions.GET_SINGLE_STUDENT_REQUEST,
    fetchSingleStudent
  );
}
function* watchGetAllStudents() {
  yield takeLatest(studentsActions.GET_ALL_STUDENTS_REQUEST, getAllStudents);
}
function* watchGetStudentByRegNo() {
  yield takeLatest(studentsActions.GET_STUDENT_REQUEST, getStudentByRegNo);
}
function* watchRegistrationStatus() {
  yield takeLatest(
    studentsActions.GET_REGISTRATION_STATUS_REQUEST,
    getRegistrationStatus
  );
}
function* watchCreateStudents() {
  yield takeLatest(studentsActions.CREATE_STUDENTS_REQUEST, createStudent);
}
function* watchFetchProgrammeStudents() {
  yield takeLatest(
    studentsActions.GET_PROGRAMME_STUDENTS_REQUEST,
    fetchProgrammeStudents
  );
}
function* watchBulkEditProgrammeVersion() {
  yield takeLatest(
    studentsActions.BULK_EDIT_PROGRAMME_VERSION_REQUEST,
    bulkEditProgrammeVersion
  );
}
function* watchStudentIdCardUpload() {
  yield takeLatest(
    studentsActions.UPLOAD_STUDENT_ID_CARD_IMAGE_REQUEST,
    uploadStudentIdCardImage
  );
}
function* watchUploadFile() {
  yield takeLatest(studentsActions.UPLOAD_STUDENT_PHOTO_REQUEST, uploadFile);
}
function* watchUpdateStudents() {
  yield takeLatest(studentsActions.UPDATE_STUDENTS_REQUEST, updateStudent);
}
function* watchUpdateStudentInfo() {
  yield takeLatest(studentsActions.UPDATE_STUDENT_REQUEST, updateStudentInfo);
}
function* watchDeleteStudents() {
  yield takeLatest(studentsActions.DELETE_STUDENTS_REQUEST, deleteStudents);
}
function* watchGetCourseUnits() {
  yield takeLatest(
    studentsActions.GET_STUDENT_PROGRAMME_COURSE_UNITS_REQUEST,
    getStudentProgrammeCourses
  );
}
function* watchGetCourseUnitsToEdit() {
  yield takeLatest(
    studentsActions.GET_STUDENT_COURSE_UNITS_TO_EDIT_REQUEST,
    getStudentCoursesToEdit
  );
}
function* watchGetFeesWaiverStudents() {
  yield takeLatest(
    studentsActions.GET_FEES_WAIVER_STUDENTS_REQUEST,
    getFeesWaiverStudents
  );
}
function* watchUpdateStudentWaiverLimit() {
  yield takeLatest(
    studentsActions.UPDATE_STUDENT_FEES_WAIVER_LIMIT_REQUEST,
    updateStudentWaiverLimit
  );
}

function* watchPrintPermit() {
  yield takeLatest(
    studentsActions.CHECK_EXAMINATION_PERMIT_REQUEST,
    printPermit
  );
}

function* watchGetLateCourseUnits() {
  yield takeLatest(
    studentsActions.GET_LATE_COURSES_TO_REGISTER_REQUEST,
    getLateCourseUnits
  );
}
function* watchGetMultipleRegistrations() {
  yield takeLatest(
    studentsActions.GET_CURRENT_REGISTRATIONS_REQUEST,
    getMultipleRegistrations
  );
}
function* watchDownloadProgrammeStudents() {
  yield takeLatest(
    studentsActions.DOWNLOAD_PROGRAMME_STUDENTS_REQUEST,
    downloadProgrammeStudents
  );
}
function* watchDownloadSRMProgrammeStudentsByContext() {
  yield takeLatest(
    studentsActions.DOWNLOAD_SRM_STUDENTS_BY_CONTEXT_REQUEST,
    downloadSRMProgrammeStudentsByContext
  );
}

function* watchEditStudentStatus() {
  yield takeLatest(
    studentsActions.EDIT_STUDENTS_STATUS_REQUEST,
    editStudentStatus
  );
}

function* watchEditSingleStudentStatus() {
  yield takeLatest(
    studentsActions.EDIT_SINGLE_STUDENT_STATUS_REQUEST,
    editSingleStudentStatus
  );
}

export default [
  fork(watchGetStudents),
  fork(watchGetContextStudents),
  fork(watchUploadFile),
  fork(watchGetStudentSDashboard),
  fork(watchGetSingleStudent),
  fork(watchGetAllStudents),
  fork(watchCreateStudents),
  fork(watchFetchProgrammeStudents),
  fork(watchBulkEditProgrammeVersion),
  fork(watchDeleteStudents),
  fork(watchUpdateStudents),
  fork(watchGetStudentByRegNo),
  fork(watchGetCourseUnits),
  fork(watchGetCourseUnitsToEdit),
  fork(watchGetLateCourseUnits),
  fork(watchRegistrationStatus),
  fork(watchUpdateStudentInfo),
  fork(watchPrintPermit),
  fork(watchGetMultipleRegistrations),
  fork(watchDownloadProgrammeStudents),
  fork(watchEditStudentStatus),
  fork(watchEditSingleStudentStatus),
  fork(watchDownloadSRMProgrammeStudentsByContext),
  fork(watchStudentIdCardUpload),
  fork(watchGetFeesWaiverStudents),
  fork(watchUpdateStudentWaiverLimit),
];
