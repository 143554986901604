import update from 'immutability-helper';
import { studentsActions } from '../../actions';
import initialState from '../../initialState';

const students = (state = initialState.students, actions) => {
  switch (actions.type) {
    case studentsActions.SHOW_STUDENT_PROFILE_MODAL:
      return {
        ...state,
        showStudentProfileModal: actions.payload,
      };
    case studentsActions.SET_LIST_VIEW_STUDENTS:
      return {
        ...state,
        studentList: actions.payload,
      };
    case studentsActions.GET_STUDENTS_REQUEST:
      return {
        ...state,
        error: {},
        loading: true,
        created: {},
      };
    case studentsActions.GET_STUDENTS_SUCCESS:
      return {
        ...state,
        students: actions.data.students,
        error: {},
        loading: false,
      };
    case studentsActions.GET_STUDENTS_ERROR:
      return {
        ...state,
        error: actions.error,
        loading: false,
      };

    case studentsActions.GET_CONTEXT_STUDENTS_REQUEST:
      return {
        ...state,
        error: {},
        gettingContextStudents: true,
      };
    case studentsActions.GET_CONTEXT_STUDENTS_SUCCESS: {
      const { context, data } = actions;
      const { contextStudents } = state;

      const findContextIndex = contextStudents.findIndex(
        (currentContext) =>
          currentContext.programme_id === context.programme_id &&
          context.academic_year_id === currentContext.academic_year_id &&
          currentContext.intake_id === context.intake_id &&
          currentContext.campus_id === context.campus_id
      );

      const contextData = { ...context, students: data.students };

      let newState = state;

      if (findContextIndex === -1) {
        newState = update(newState, {
          contextStudents: { $push: [contextData] },
        });
      } else {
        newState = update(newState, {
          contextStudents: { [findContextIndex]: { $set: contextData } },
        });
      }

      return {
        ...newState,
        gettingContextStudents: false,
      };
    }

    case studentsActions.GET_CONTEXT_STUDENTS_ERROR:
      return {
        ...state,
        error: actions.error,
        gettingContextStudents: false,
      };

    case studentsActions.GET_STUDENT_REQUEST:
      return {
        ...state,
        error: {},
        loading: true,
        selectedStudent: {},
      };
    case studentsActions.GET_STUDENT_SUCCESS:
      return {
        ...state,
        error: {},
        loading: false,
        selectedStudent: actions.data.data,
      };
    case studentsActions.GET_STUDENT_ERROR:
      return {
        ...state,
        error: actions.error,
        loading: false,
      };

    case studentsActions.GET_DASHBOARD_STUDENTS_REQUEST:
      return {
        ...state,
        fetching: true,
        dashboardError: {},
      };
    case studentsActions.GET_DASHBOARD_STUDENTS_SUCCESS:
      return {
        ...state,
        fetching: false,
        dashboardData: actions.data,
      };
    case studentsActions.GET_DASHBOARD_STUDENTS_ERROR:
      return {
        ...state,
        dashboardError: actions.error,
        fetching: false,
      };

    case studentsActions.GET_REGISTRATION_STATUS_REQUEST:
      return {
        ...state,
        error: {},
        loading: true,
        enrolmentStatus: {},
        registrationStatus: {},
        semesterBoundRegistration: {},
      };
    case studentsActions.GET_REGISTRATION_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        enrolmentStatus: actions.data.studentEnrollment,
        registrationStatus: actions.data.studentRegistration,
        semesterBoundRegistration: actions.data,
      };
    case studentsActions.GET_REGISTRATION_STATUS_ERROR:
      return {
        ...state,
        loading: false,
        error: actions.error,
        enrolmentStatus: {},
        registrationStatus: {},
        semesterBoundRegistration: {},
      };

    case studentsActions.GET_SINGLE_STUDENT_REQUEST:
      return {
        ...state,
        fetchingOne: true,
        uploadStudent: {},
      };

    case studentsActions.GET_SINGLE_STUDENT_SUCCESS:
      return {
        ...state,
        studentIdentifier: actions.studentIdentifier,
        fetchingOne: true,
      };

    case studentsActions.GET_SINGLE_STUDENT_ERROR:
      return {
        ...state,
        fetchingOne: false,
      };
    case studentsActions.GET_ALL_STUDENTS_REQUEST:
      return {
        ...state,
        allError: {},
        loadingAll: true,
      };
    case studentsActions.GET_ALL_STUDENTS_SUCCESS:
      return {
        ...state,
        allStudents: actions.data.students,
        allError: {},
        loadingAll: false,
      };
    case studentsActions.GET_ALL_STUDENTS_ERROR:
      return {
        ...state,
        allError: actions.error,
        loadingAll: false,
      };

    case studentsActions.GET_FEES_WAIVER_STUDENTS_REQUEST:
      return {
        ...state,
        getWaiverStudentsError: {},
        gettingWaiverStudents: true,
      };
    case studentsActions.GET_FEES_WAIVER_STUDENTS_SUCCESS:
      return {
        ...state,
        waiverStudents: actions.data,
        getWaiverStudentsError: {},
        gettingWaiverStudents: false,
      };
    case studentsActions.GET_FEES_WAIVER_STUDENTS_ERROR:
      return {
        ...state,
        getWaiverStudentsError: actions.error,
        gettingWaiverStudents: false,
      };

    case studentsActions.UPDATE_STUDENT_FEES_WAIVER_LIMIT_REQUEST:
      return {
        ...state,
        updateLimitError: {},
        updatingWaiverLimit: true,
      };
    case studentsActions.UPDATE_STUDENT_FEES_WAIVER_LIMIT_SUCCESS:
      return {
        ...state,
        updateLimitSuccess: actions.data,
        updateLimitError: {},
        updatingWaiverLimit: false,
      };
    case studentsActions.UPDATE_STUDENT_FEES_WAIVER_LIMIT_ERROR:
      return {
        ...state,
        updateLimitError: actions.error,
        updatingWaiverLimit: false,
      };

    case studentsActions.CREATE_STUDENTS_REQUEST:
      return {
        ...state,
        creating: true,
        error: {},
      };

    case studentsActions.CREATE_STUDENTS_SUCCESS:
      return {
        ...state,
        creating: false,
        created: actions.data,
      };

    case studentsActions.CREATE_STUDENTS_ERROR:
      return {
        ...state,
        creating: false,
        error: actions.error,
      };

    case studentsActions.BULK_EDIT_PROGRAMME_VERSION_REQUEST:
      return {
        ...state,
        updatingVersion: true,
        updateVersionError: {},
      };

    case studentsActions.BULK_EDIT_PROGRAMME_VERSION_SUCCESS:
      return {
        ...state,
        updatingVersion: false,
        updateVersionResponse: actions.data,
      };

    case studentsActions.BULK_EDIT_PROGRAMME_VERSION_ERROR:
      return {
        ...state,
        updatingVersion: false,
        updateVersionError: actions.error,
      };

    case studentsActions.SHOW_EDIT_VERSION_MODAL:
      return {
        ...state,
        showEditVersion: actions.payload,
      };

    case studentsActions.SHOW_EDIT_ACCOUNT_MODAL:
      return {
        ...state,
        showEditAccount: actions.payload,
      };

    case studentsActions.SHOW_SEARCH_ACCOUNT_MODAL:
      return {
        ...state,
        showSearchAccount: actions.payload,
      };

    case studentsActions.GET_PROGRAMME_STUDENTS_REQUEST:
      return {
        ...state,
        fetchingProgStudents: true,
        fetchProgStudentsError: {},
      };

    case studentsActions.GET_PROGRAMME_STUDENTS_SUCCESS: {
      const { context, data } = actions;
      const { studentsProgContext } = state;

      const findContextIndex = studentsProgContext.findIndex(
        (currentContext) => {
          return (
            currentContext.programme_id === context.programme_id &&
            context.academic_year_id === currentContext.academic_year_id
          );
        }
      );

      const contextData = { ...context, data };

      let newState = state;

      if (findContextIndex === -1) {
        newState = update(newState, {
          studentsProgContext: { $push: [contextData] },
        });
      } else {
        newState = update(newState, {
          studentsProgContext: { [findContextIndex]: { $set: contextData } },
        });
      }

      return {
        ...newState,
        fetchingProgStudents: false,
      };
    }

    case studentsActions.GET_PROGRAMME_STUDENTS_ERROR:
      return {
        ...state,
        fetchingProgStudents: false,
        fetchProgStudentsError: actions.error,
      };

    case studentsActions.UPLOAD_STUDENT_PHOTO_REQUEST:
      return {
        ...state,
        uploadStudentPhoto: true,
        uploadStudentPhotoError: {},
      };
    case studentsActions.UPLOAD_STUDENT_PHOTO_SUCCESS:
      return {
        ...state,
        uploadStudentPhoto: false,
        uploadedStudentPhoto: actions.data,
      };
    case studentsActions.UPLOAD_STUDENT_PHOTO_ERROR:
      return {
        ...state,
        uploadStudentPhoto: false,
        uploadStudentPhotoError: {},
      };

    case studentsActions.UPLOAD_STUDENT_ID_CARD_IMAGE_REQUEST:
      return {
        ...state,
        uploadStudentIdCardImage: true,
        uploadedStudentIdCardImage: {},
      };
    case studentsActions.UPLOAD_STUDENT_ID_CARD_IMAGE_SUCCESS:
      return {
        ...state,
        uploadStudentIdCardImage: false,
        uploadedStudentIdCardImage: actions.data,
      };
    case studentsActions.UPLOAD_STUDENT_ID_CARD_IMAGE_ERROR:
      return {
        ...state,
        uploadStudentIdCardImage: false,
        uploadStudentIdCardImageError: actions.error,
      };

    case studentsActions.SET_UPLOAD_ID_IMAGE_CONTEX:
      return {
        ...state,
        uploadIdImageContext: actions.context,
      };

    case studentsActions.EDIT_STUDENTS_STATUS_REQUEST:
      return {
        ...state,
        editingStatus: true,
        editStatusError: {},
      };
    case studentsActions.EDIT_STUDENTS_STATUS_SUCCESS:
      return {
        ...state,
        editStatusSuccess: actions.data,
        editingStatus: false,
      };
    case studentsActions.EDIT_STUDENTS_STATUS_ERROR:
      return {
        ...state,
        editingStatus: false,
        editStatusError: actions.error,
      };

    case studentsActions.EDIT_SINGLE_STUDENT_STATUS_REQUEST:
      return {
        ...state,
        editingStatus: true,
        editStatusError: {},
      };
    case studentsActions.EDIT_SINGLE_STUDENT_STATUS_SUCCESS:
      return {
        ...state,
        editStatusSuccess: actions.data,
        editingStatus: false,
      };
    case studentsActions.EDIT_SINGLE_STUDENT_STATUS_ERROR:
      return {
        ...state,
        editingStatus: false,
        editStatusError: actions.error,
      };

    case studentsActions.SET_STUDENTS_CONTEXT:
      return {
        ...state,
        studentsContext: actions.context,
      };
    case studentsActions.SET_NEW_STUDENTS_CONTEXT:
      return {
        ...state,
        studentContext: actions.context,
      };
    case studentsActions.SET_SELECTED_STUDENT:
      return {
        ...state,
        selectedStudent: actions.student,
      };
    case studentsActions.SET_UPLOAD_STUDENT:
      return {
        ...state,
        uploadStudent: actions.data,
        fetchingOne: false,
      };
    case studentsActions.SET_IDENTIFYING_STUDENT:
      return {
        ...state,
        identifyingStudent: actions.data,
        fetchingOne: false,
      };
    case studentsActions.SET_SELECTED_STUDENT_PROGRAMME:
      return {
        ...state,
        selectedProgramme: actions.programme,
      };
    case studentsActions.DELETE_STUDENTS_REQUEST:
      return {
        ...state,
        deleteResponse: {},
        deleting: true,
        deletingId: actions.id,
      };

    case studentsActions.DELETE_STUDENTS_SUCCESS:
      return {
        ...state,
        deleting: false,
        deleteResponse: actions.data,
      };

    case studentsActions.DELETE_STUDENTS_ERROR:
      return {
        ...state,
        deleting: false,
        deleteError: actions.error,
      };

    case studentsActions.UPDATE_STUDENTS_REQUEST:
      return {
        ...state,
        updateResponse: {},
        updating: true,
        updatingId: actions.id,
      };

    case studentsActions.UPDATE_STUDENTS_SUCCESS:
      return {
        ...state,
        updating: false,
        updateResponse: actions.data,
      };

    case studentsActions.UPDATE_STUDENTS_ERROR:
      return {
        ...state,
        updating: false,
        updateError: actions.error,
      };

    case studentsActions.UPDATE_STUDENT_REQUEST:
      return {
        ...state,
        updateResponse: {},
        updating: true,
        updatingId: actions.id,
      };

    case studentsActions.UPDATE_STUDENT_SUCCESS:
      return {
        ...state,
        updating: false,
        updateResponse: actions.data,
      };

    case studentsActions.UPDATE_STUDENT_ERROR:
      return {
        ...state,
        updating: false,
        updateError: actions.error,
      };

    case studentsActions.GET_STUDENT_PROGRAMME_COURSE_UNITS_REQUEST:
      return {
        ...state,
        gettingProgrammeCourses: true,
        programmeCourseError: {},
      };

    case studentsActions.GET_STUDENT_PROGRAMME_COURSE_UNITS_SUCCESS:
      return {
        ...state,
        gettingProgrammeCourses: false,
        programmeCourses: actions.data.data,
      };

    case studentsActions.GET_STUDENT_PROGRAMME_COURSE_UNITS_ERROR:
      return {
        ...state,
        gettingProgrammeCourses: false,
        programmeCourseError: actions.error,
      };

    case studentsActions.GET_STUDENT_COURSE_UNITS_TO_EDIT_REQUEST:
      return {
        ...state,
        gettingProgrammeCourses: true,
        studentCoursesToEditError: {},
      };

    case studentsActions.GET_STUDENT_COURSE_UNITS_TO_EDIT_SUCCESS:
      return {
        ...state,
        gettingProgrammeCourses: false,
        studentCoursesToEdit: actions.data.data,
      };

    case studentsActions.GET_STUDENT_COURSE_UNITS_TO_EDIT_ERROR:
      return {
        ...state,
        gettingProgrammeCourses: false,
        studentCoursesToEditError: actions.error,
      };

    case studentsActions.CHECK_EXAMINATION_PERMIT_REQUEST:
      return {
        ...state,
        checkingExamPermit: true,
        examPermitError: {},
      };

    case studentsActions.CHECK_EXAMINATION_PERMIT_SUCCESS: {
      let { allExamPermits } = state;
      const findPermitIndex = allExamPermits.findIndex(
        (permit) => permit.registrationId === actions.registrationId
      );

      if (findPermitIndex !== -1) {
        allExamPermits[findPermitIndex] = {
          registrationId: actions.registrationId,
          enabled: true,
        };
      } else {
        allExamPermits = [
          ...allExamPermits,
          {
            registrationId: actions.registrationId,
            enabled: true,
          },
        ];
      }
      return {
        ...state,
        allExamPermits,
        checkingExamPermit: false,
      };
    }

    case studentsActions.CHECK_EXAMINATION_PERMIT_ERROR:
      return {
        ...state,
        checkingExamPermit: false,
        examPermitError: actions.error,
      };

    case studentsActions.GET_LATE_COURSES_TO_REGISTER_REQUEST:
      return {
        ...state,
        gettingProgrammeCourses: true,
        lateProgrammeCourseError: {},
      };

    case studentsActions.GET_LATE_COURSES_TO_REGISTER_SUCCESS:
      return {
        ...state,
        gettingProgrammeCourses: false,
        lateProgrammeCourses: actions.data,
      };

    case studentsActions.GET_LATE_COURSES_TO_REGISTER_ERROR:
      return {
        ...state,
        gettingProgrammeCourses: false,
        lateProgrammeCourseError: actions.error,
      };

    case studentsActions.GET_CURRENT_REGISTRATIONS_REQUEST:
      return {
        ...state,
        gettingMultipleRegistrations: true,
        multipleRegistrationsError: [],
      };

    case studentsActions.GET_CURRENT_REGISTRATIONS_SUCCESS: {
      const { data, registrationIds } = actions;

      const { multipleRegistrations } = state;

      let newState = state;

      registrationIds?.forEach((id) => {
        const newRegistrationRecord = data.find((record) => record.id === id);
        if (
          multipleRegistrations.findIndex((record) => record.id === id) === -1
        ) {
          newState = update(newState, {
            multipleRegistrations: { $push: [newRegistrationRecord] },
          });
        } else {
          const existingRegistrationIndex = multipleRegistrations.findIndex(
            (record) => record.id === id
          );
          newState = update(newState, {
            multipleRegistrations: {
              [existingRegistrationIndex]: { $set: newRegistrationRecord },
            },
          });
        }
      });

      return {
        ...newState,
        gettingMultipleRegistrations: false,
      };
    }

    case studentsActions.GET_CURRENT_REGISTRATIONS_ERROR:
      return {
        ...state,
        gettingMultipleRegistrations: false,
        multipleRegistrationsError: actions.error,
      };

    case studentsActions.DOWNLOAD_PROGRAMME_STUDENTS_REQUEST:
      return {
        ...state,
        downloadingStudents: true,
        downloadingStudentsError: {},
        downloadingStudentsSuccess: {},
      };
    case studentsActions.DOWNLOAD_PROGRAMME_STUDENTS_SUCCESS:
      return {
        ...state,
        downloadingStudents: false,
        downloadingStudentsSuccess: actions.data,
      };
    case studentsActions.DOWNLOAD_PROGRAMME_STUDENTS_ERROR:
      return {
        ...state,
        downloadingStudents: false,
        downloadingStudentsError: actions.data,
      };

    case studentsActions.DOWNLOAD_SRM_STUDENTS_BY_CONTEXT_REQUEST:
      return {
        ...state,
        downloadingStudents: true,
        downloadingStudentsError: {},
        downloadingStudentsSuccess: {},
      };
    case studentsActions.DOWNLOAD_SRM_STUDENTS_BY_CONTEXT_SUCCESS:
      return {
        ...state,
        downloadingStudents: false,
        downloadingStudentsSuccess: actions.data,
      };
    case studentsActions.DOWNLOAD_SRM_STUDENTS_BY_CONTEXT_ERROR:
      return {
        ...state,
        downloadingStudents: false,
        downloadingStudentsError: actions.data,
      };

    default:
      return state;
  }
};
export default students;
