const systemUser = {
  GET_USERS_REQUEST: 'GET_USERS_REQUEST',
  GET_USERS_SUCCESS: 'GET_USERS_SUCCESS',
  GET_USERS_ERROR: 'GET_USERS_ERROR',

  CREATE_USER_SUCCESS: 'CREATE_USER_SUCCESS',
  CREATE_USER_REQUEST: 'CREATE_USER_REQUEST',
  CREATE_USER_ERROR: 'CREATE_USER_ERROR',

  UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
  UPDATE_USER_REQUEST: 'UPDATE_USER_REQUEST',
  UPDATE_USER_ERROR: 'UPDATE_USER_ERROR',

  DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
  DELETE_USER_REQUEST: 'DELETE_USER_REQUEST',
  DELETE_USER_ERROR: 'DELETE_USER_ERROR',

  GET_LECTURERS_REQUEST: 'GET_LECTURERS_REQUEST',
  GET_LECTURERS_SUCCESS: 'GET_LECTURERS_SUCCESS',
  GET_LECTURERS_ERROR: 'GET_LECTURERS_ERROR',

  GET_DEPARTMENT_LECTURERS_REQUEST: 'GET_DEPARTMENT_LECTURERS_REQUEST',
  GET_DEPARTMENT_LECTURERS_SUCCESS: 'GET_DEPARTMENT_LECTURERS_SUCCESS',
  GET_DEPARTMENT_LECTURERS_ERROR: 'GET_DEPARTMENT_LECTURERS_ERROR',

  SHOW_USER_MODAL: 'SHOW_USER_MODAL',

  getUserData: (data) => ({
    type: systemUser.GET_USERS_REQUEST,
    data,
  }),

  createNewUser: (data, roleGroupId) => ({
    type: systemUser.CREATE_USER_REQUEST,
    data,
    roleGroupId,
  }),

  updateUser: (id, data, roleGroupId, action) => ({
    type: systemUser.UPDATE_USER_REQUEST,
    id,
    data,
    roleGroupId,
    action,
  }),

  deleteUser: (id, data) => ({
    type: systemUser.DELETE_USER_REQUEST,
    id,
    data,
  }),

  getLecturers: () => ({
    type: systemUser.GET_LECTURERS_REQUEST,
  }),

  getDepartmentLecturers: (departmentId) => ({
    type: systemUser.GET_DEPARTMENT_LECTURERS_REQUEST,
    departmentId,
  }),

  showUserModal: (payload) => ({
    type: systemUser.SHOW_USER_MODAL,
    payload,
  }),
};

export default systemUser;
